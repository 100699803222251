import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'KGHAPPY';
    src: url('/fonts/KGHAPPY.ttf') format('truetype'); /* Adjust the path if necessary */
    font-weight: normal;
    font-style: normal;
  }

    @font-face {
    font-family: 'KGHAPPYSOLID';
    src: url('/fonts/KGHAPPYSOLID.ttf') format('truetype'); /* Adjust the path if necessary */
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'KGHAPPY', sans-serif;
  }
`;

export default GlobalStyles;
