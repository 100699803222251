import styled from "styled-components";

import GlobalStyles from './GlobalStyles';

import { Grid, Button } from '@mui/material';

import buythedip from './assets/buythedip.png';
import chippy from './assets/chippy.png';
import chippysmoking from './assets/chippysmoking.png';
import chippywifhat from './assets/chippywifhat.png';
import instagram from './assets/instagram.png';
import telegram from './assets/tg.png';
import x from './assets/x.png';
import dexscreener from './assets/dexscreener.png';
import tiktok from './assets/tiktok.png';
import chippybw from './assets/chippybw.png';

import meme1 from './assets/meme1.png';
import meme2 from './assets/meme2.png';
import meme3 from './assets/meme3.png';
import meme4 from './assets/meme4.png';
import meme5 from './assets/meme5.png';

import potatochips from './assets/potatochips.png';

import './App.css';

const StyledIcon = styled.img`
  margin-top: 45px;
  height: 60px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition for smooth animation */

  /* Define hover effect */
  &:hover {
    transform: scale(1.5); /* Increase size by 50% */
  }

  @media (max-width: 899px) {
    height: 40px;
  }

`

const StyledMenu = styled.div`
`



const StyledHeaderLink = styled.span`
  font-size: 26px;
  line-height: 127px;
  cursor: pointer;
  @media (max-width: 1440px) {
    font-size: 22px;
  }
  @media (max-width: 899px) {
    font-size: 12px;
      line-height: 147px;

  }
  z-index: 2000;

  &:hover {
    opacity: 0.7;
  }


`

const Hero = () => {

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
  return (
    <StyledHero>

      <StyledChippyWifHat src={chippywifhat} />
      <StyledResponsiveChippy src={chippy} />
      <StyledHeader>
        <Grid container direction="row" spacing={{xs:2, md:5}} justifyContent={"center"} alignItems={"center"}>

          <Grid item>
            <StyledHeaderLink onClick={() => handleScroll('about')}>
              ABOUT
            </StyledHeaderLink>
          </Grid>
          <Grid item>
            <StyledHeaderLink onClick={() => handleScroll('tokenomics')}>
              TOKENOMICS
            </StyledHeaderLink>
          </Grid>
          <Grid item>
            <StyledHeaderLink  onClick={() => window.open("https://x.com/meetchippy", "_blank")}>
              TWITTER
            </StyledHeaderLink>
          </Grid>
          <Grid item>
            <StyledHeaderLink  onClick={() => window.open("https://t.me/meetchippy", "_blank")}>
              TELEGRAM
            </StyledHeaderLink>
          </Grid>
          <Grid item>
            <StyledHeaderLink  onClick={() => window.open("https://dexscreener.com/solana/hnmhwv9nmgsfzd3daffuxdwgunql8rdddwzijkmexpyh", "_blank")}>
              DEX
            </StyledHeaderLink>
          </Grid>
        </Grid>

      </StyledHeader>
      <Grid container direction={"row"} alignItems={"center"} justifyContent={"center"}>
        <Grid item>
          <Grid container direction={"column"}>
            <Grid item>
              <StyledHeroText>
                $CHIPPY IS THE
                CRISPIEST POTATO
                AROUND! LET´S EAT
                SOME CHIPS!
              </StyledHeroText>
            </Grid>
            <Grid item>
              <StyledHeroButton variant="contained" onClick={() => window.open("https://raydium.io/swap/?outputMint=HqrAGvyVPMfCKRX2V4UixyW7t7biVSvJPr8me5JTpump&inputMint=7xywcaBrF6vQVyHJ1eM9LdT5Z45EAbLbDgGuh8i5pump")}>
                Buy Now
              </StyledHeroButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <StyledMainChip src={chippy} />
        </Grid>
      </Grid>
    </StyledHero>
  )
}

const StyledResponsiveChippy = styled.img`
    display: none;
    @media (max-width: 899px) {
      display: block;
      position: absolute;
      bottom: 150px;
      right: 0;  
      height: 300px;
  }
  @media (max-width: 599px) {
      display: block;
      position: absolute;
      bottom: 100px;
      right: 0;  
      height: 200px;
    }
`

const StyledChippyWifHat = styled.img`
  position: absolute;
  left: 0;
  height: 415px;
  @media (max-width: 1400px) {
    top: 25px;
    height: 260px;
  }
  @media (max-width: 899px) {
    top: 80px;
    height: 200px;
  }
`


const StyledHeader = styled.header`
  height: 167px;
  background: rgba(173,173,173,0.6);
`


const StyledHero = styled.div`
  background-image: url(${potatochips}); 
  background-size: cover;
  @media (max-width: 1400px) {
    padding-bottom: 100px;
  }
`

const StyledHeroText = styled.p`
  max-width:650px;
  font-size: 58px;
  line-height: 58px;
  // font-family: KGHAPPYSOLID;
  
  @media (max-width: 1400px) {
    padding-top: 90px;
    max-width:400px;
    font-size: 36px;
    line-height: 42px;
  }
  @media (max-width: 899px) {
    padding-top: 90px;
    max-width:400px;
    font-size:26px;
    line-height: 42px;
  }
`

const StyledHeroButton = styled(Button)`
  && {
    // font-family: KGHAPPYSOLID;
    background: #2C2C2C;
    padding: 10px;
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }

    @media (max-width: 599px) {
  && {
    // font-family: KGHAPPYSOLID;
    background: #2C2C2C;
    padding: 10px;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }

}
`


const StyledMainChip = styled.img`

  @media (max-width: 1400px) {
    height: 400px;
  }

  @media (max-width: 899px) {
    display: none;
  }

`

const Tokenomics = () => {

  return (
    <StyledTokenomics>
      <Grid container direction={"column"} >
        <MeetChippy />
        <StyledSmokingChippy src={chippysmoking} />
        <TokenomicsDetails />
        <Memes />
        <Footer />


      </Grid>

    </StyledTokenomics>
  )
}

const StyledSmokingChippy = styled.img`
  position: absolute;
  top: 450px;
  right: 200px;
  height: 349px;
  z-index: 100px;

  @media (max-width: 1400px) {
    height: 200px;
  } 

  @media (max-width: 899px) {
  height: 140px;
  right: 100px;
  top: 400px;
  }


`

const StyledTokenomics = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${chippybw}); /* Image path relative to the public directory */
  background-size: 150px 150px; /* Set the size of the background image */
  background-repeat: repeat; /* Repeat the background image */
  background-position: top left; /* Start the background image from the top left corner */
  padding-top: 140px;
`

const MeetChippy = () => {
  return (
    <Grid item id="about">


      <Grid container direction={{ xs: 'column', md: 'row' }} alignContent={"center"} spacing={8} justifyContent={"center"}>
        <Grid item>
          <StyledBTD src={buythedip} />
        </Grid>


        <Grid item>
          <StyledContainer>
          <StyledMeetChippyContainer>
            <Grid container direction={"column"}>
              <Grid item>
                <StyledMeetChippyTitle>
                  MEET CHIPPY
                </StyledMeetChippyTitle>
              </Grid>
              <Grid item>
                <StyledMeetChippy>
                  $CHIPPY LOVES TO BE EATEN IN DIPS AND MAKE EVERYONE HAPPY WITH A FULL TUMMY!
                  GRAB A BAG AND JOIN US IN THIS JOURNEY!
                </StyledMeetChippy>
              </Grid>
            </Grid>
          </StyledMeetChippyContainer>
          </StyledContainer>
        </Grid>
      </Grid>
    </Grid>

  )
}

const StyledBTD = styled.img`
  height: 400px;
  @media (max-width: 899px) {
  height: 300px;
}
`

const StyledMeetChippyTitle = styled.span`
  display: block;
  margin-top: 50px;
  font-size: 64px;

  @media (max-width: 899px) {
    font-size: 40px;
    line-height: 40px;
  }

`
const StyledMeetChippy = styled.p`
  max-width: 500px;
  font-size: 26px;
  line-height: 26px;
  // font-family: KGHAPPYSOLID;

  @media (max-width: 899px) {
    font-size: 20px;
    line-height: 20px;
  }

`
const StyledContainer = styled.div`

  @media (max-width: 899px) {
    height: 300px;
    padding-left: 10px;
    padding-right: 10px;
  }    
`

const StyledMeetChippyContainer = styled.div`
  border-radius: 50px;
  background: #F2D694;
  max-height: 400px;
  height: 400px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 200px;

  @media (max-width: 899px) {
    height: 300px;
    
  }
`

const TokenomicsDetails = () => {
  return (
    <Grid item id="tokenomics">
      <Grid container direction={"column"} spacing={5} alignContent={"center"} justifyContent={"center"}>
        <Grid item>
          <StyledCA>
            <StyledItemTitle>
              $CHIPPY
            </StyledItemTitle>
            <StyledCAText>
              CA: HqrAGvyVPMfCKRX2V4UixyW7t7biVSvJPr8me5JTpump
            </StyledCAText>
          </StyledCA>

        </Grid>
        <Grid item>
          <Grid container direction={{ xs: 'column', md: 'row' }} justifyContent={{ xs: "center", md: "space-between" }} alignItems={"center"} spacing={2}>

            <Grid item>
              <TokenomicsItem>
                <StyledItemTitle>
                  SUPPLY
                </StyledItemTitle>
                <StyledItemText>
                  1,000,000,000 $CHIPPY
                </StyledItemText>
              </TokenomicsItem>

            </Grid>
            <Grid item>
              <TokenomicsItem>
                <StyledItemTitle>
                  LIQUIDITY
                </StyledItemTitle>
                <StyledItemText>
                  100% BURNT
                </StyledItemText>
              </TokenomicsItem>

            </Grid>
            <Grid item>
              <TokenomicsItem>
                <StyledItemTitle>
                  TAX
                </StyledItemTitle>
                <StyledItemText>
                  0/0
                </StyledItemText>
              </TokenomicsItem>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const StyledCA = styled.div`
  max-width: calc(100vw - 40px);
  border-radius: 50px;
  background: #F2D694;
  padding: 20px;
  z-index: 200;
      @media (max-width: 899px) {
    margin-top: 140px;
  }

    @media (max-width: 599px) {
    margin-top: 140px;
  }

  
`

const StyledCAText = styled.span`
  display: block;
  font-size: 32px;
  line-height: 50px;
  // font-family: KGHAPPYSOLID;
  max-width: calc(100vw - 40px);

  @media (max-width: 1400px) {
    font-size: 24px;
  }

  @media (max-width: 899px) {
    font-size: 16px;
  }

  @media (max-width: 599px) {
    font-size: 10px;
  }
  
`

const TokenomicsItem = styled.div`
  border-radius: 50px;
  background: #F2D694;
  width: 350px;
  padding: 20px;

  @media (max-width: 1200px) {
    width: 240px;
  }
`

const StyledItemTitle = styled.span`
  display: block;
  font-size: 32px;
  // font-family: KGHAPPYSOLID;
  line-height: 50px;
  @media (max-width: 1200px) {
    font-size: 24px;
  }
`

const StyledItemText = styled.span`
  font-size: 24px;
  // font-family: KGHAPPYSOLID;
  display: block;

  @media (max-width: 1200px) {
  font-size: 16px;

}

`

const Memes = () => {
  return (
    <StyledMemes>
      <StyledSpecialMemeFloater src={meme5} />

      <Grid container direction="column" alignContent={"center"} justifyContent={"space-around"} spacing={{ xs: 2, md: 20 }}>
        <Grid item>
          <Grid container direction={"row"} spacing={{ xs: 2, md: 20 }}>
            <Grid item xs={12} md={6}>
              <StyledMeme src={meme1} />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMeme src={meme2} />
            </Grid>
          </Grid>

        </Grid>
        <Grid item>
          <Grid container direction={"row"} spacing={{ xs: 2, md: 20 }}>
            <Grid item xs={12} md={6}>
              <StyledMeme src={meme3} />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledMeme src={meme4} />
            </Grid>
          </Grid>
        </Grid>

        <StyledSpecialMeme item>
          <StyledMeme src={meme5} />
        </StyledSpecialMeme>
      </Grid>
    </StyledMemes>

  )
}

const StyledSpecialMemeFloater = styled.img`
  height: 400px;
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translate(-50%,-0%);
  @media (max-width: 899px) {
    display: none;
  }

  

`

const StyledSpecialMeme = styled(Grid)`
  display: none;
  @media (max-width: 899px) {
    display: block;
  }

  

`

const StyledMemes = styled.div`
  position: relative;
  margin-top: 144px;
  margin-bottom: 144px;
`
const StyledMeme = styled.img`
  height: 400px;

  @media (max-width: 599px) {
    height: 300px;
  }

`

const Footer = () => {
  return (
    <StyledFooter>

      <StyledFooterLogo src={chippy} />
      <StyledFooterEmail>
        <Grid container direction={"column"}>
          <Grid item>
            <StyledTicker>
              $CHIPPY
            </StyledTicker>
          </Grid>
          <Grid item>
            <StyledEmail>
              chippyonsolana@proton.me
            </StyledEmail>

          </Grid>
        </Grid>

      </StyledFooterEmail>

      <Grid container direction="row" spacing={5} justifyContent={"center"} alignItems={"center"}>

        <Grid item>
          <StyledIcon src={telegram} onClick={() => window.open("https://t.me/meetchippy", "_blank")}></StyledIcon>
        </Grid>

        <Grid item>
          <StyledIcon src={instagram} onClick={() => window.open("", "_blank")}></StyledIcon>
        </Grid>

        <Grid item>
          <StyledIcon src={dexscreener} onClick={() => window.open("https://dexscreener.com/solana/hnmhwv9nmgsfzd3daffuxdwgunql8rdddwzijkmexpyh", "_blank")}></StyledIcon>
        </Grid>

        <Grid item>
          <StyledIcon src={x} onClick={() => window.open("https://x.com/meetchippy", "_blank")}></StyledIcon>
        </Grid>

        <Grid item>
          <StyledIcon src={tiktok} onClick={() => window.open("", "_blank")}></StyledIcon>
        </Grid>

      </Grid>

      <StyledEmailMobile>
              chippyonsolana@proton.me
            </StyledEmailMobile>

    </StyledFooter>
  )
}


const StyledEmailMobile = styled.span`
  // font-family: KGHAPPYSOLID;
  font-size: 16px;
    display: block;


`
const StyledFooterEmail = styled.div`
  position: absolute;
  right: 20px;
  bottom: 30px;

  @media (max-width: 899px) {
    display: none;
  }

`

const StyledEmail = styled.span`
  // font-family: KGHAPPYSOLID;
  font-size: 16px;
`

const StyledTicker = styled.span`
  display: block;
  margin-bottom: 20px;
  font-family: KGHAPPY;
  font-size: 32px;
`

const StyledFooterLogo = styled.img`
  position: absolute;
  height: 150px;
  width: 150px;
  left: 0;
  bottom: 0;
  @media (max-width: 899px) {
    display: none;
  }

`

const StyledFooter = styled.div`
  height: 150px;
  position: relative;
  background: #F2D694;
`



function App() {
  return (
    <>
      <GlobalStyles />

      <div className="App">


        <Hero />
        <Tokenomics />

      </div>
    </>

  );
}

export default App;
